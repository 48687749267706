import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    home: 'Home',
    createTranscription: 'Create Transcription',
    enterYoutubeUrl: 'Enter YouTube URL',
    youtubeUrl: 'YouTube URL',
    loadingTranscription: 'Loading transcription...',
    transcriptionError: 'Error creating transcription',
    transcriptionFetchError: 'Error fetching transcription',
    enterPassword: 'Enter password',
    deleteTranscription: 'Delete Transcription',
    enterPasswordToDelete: 'Enter password to delete',
    transcriptionDeleteError: 'Error deleting transcription',
    login: 'Login',
    enterUsername: 'Enter username',
    loginError: 'Login error',
    logout: 'Logout',
    transcriptionsList: 'Transcriptions List',
    noTranscriptions: 'No transcriptions available',
    invalidUrl: 'Invalid URL',
    summary: 'Summary',
    transcriptionStatus: 'Transcription Status',
    progress: 'Progress',
    viewTranscription: 'View Transcription',
    downloading: 'Downloading',
    transcribing: 'Transcribing',
    summarizing: 'Summarizing',
    completed: 'Completed',
    processing: 'Processing',
    englishSummary: 'English Summary',
    russianSummary: 'Russian Summary',
    germanSummary: 'German Summary',
    frenchSummary: 'French Summary',
    transcription: 'Transcription',
    selectLanguage: 'Select Language',
    video: 'Video'
  },
  ru: {
    home: 'Главная',
    createTranscription: 'Создать транскрипцию',
    enterYoutubeUrl: 'Введите URL YouTube',
    youtubeUrl: 'URL YouTube',
    loadingTranscription: 'Загрузка транскрипции...',
    transcriptionError: 'Ошибка при создании транскрипции',
    transcriptionFetchError: 'Ошибка при получении транскрипции',
    enterPassword: 'Введите пароль',
    deleteTranscription: 'Удалить транскрипцию',
    enterPasswordToDelete: 'Введите пароль для удаления',
    transcriptionDeleteError: 'Ошибка при удалении транскрипции',
    logout: 'Выйти',
    transcriptionsList: 'Список транскрипций',
    noTranscriptions: 'Нет доступных транскрипций',
    invalidUrl: 'Неверный URL',
    summary: 'Краткое содержание',
    englishSummary: 'Краткое содержание на английском',
    russianSummary: 'Краткое содержание на русском',
    germanSummary: 'Краткое содержание на немецком',
    frenchSummary: 'Краткое содержание на французском',
    transcription: 'Транскрипция',
    selectLanguage: 'Выберите язык'
  }
}

const i18n = createI18n({
  legacy: false,
  locale: 'en', // default locale
  fallbackLocale: 'en',
  messages,
  globalInjection: true, // This makes $t available in all components
})

export default i18n