import { createStore } from 'vuex';

export default createStore({
  state: {
    isLoggedIn: false,
    isDarkTheme: false,
  },
  mutations: {
    setLoggedIn(state, value) {
      state.isLoggedIn = value;
    },
    setDarkTheme(state, value) {
      state.isDarkTheme = value;
      console.log('Theme changed:', value ? 'dark' : 'light');
    },
  },
  actions: {
    checkLoginStatus({ commit }) {
      const token = localStorage.getItem('token');
      const tokenExpiration = localStorage.getItem('tokenExpiration');
      if (token && tokenExpiration && new Date().getTime() <= parseInt(tokenExpiration)) {
        commit('setLoggedIn', true);
      } else {
        commit('setLoggedIn', false);
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiration');
      }
    },
    login({ commit }, token) {
      const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; // 24 hours from now
      localStorage.setItem('token', token);
      localStorage.setItem('tokenExpiration', expirationTime.toString());
      commit('setLoggedIn', true);
    },
    logout({ commit }) {
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpiration');
      commit('setLoggedIn', false);
    },
    toggleTheme({ commit, state }) {
      console.log('Toggling theme');
      const newTheme = !state.isDarkTheme;
      commit('setDarkTheme', newTheme);
      localStorage.setItem('isDarkTheme', JSON.stringify(newTheme));
      console.log('New theme set:', newTheme ? 'dark' : 'light');
    },
    initTheme({ commit }) {
      console.log('Initializing theme');
      const savedTheme = localStorage.getItem('isDarkTheme');
      console.log('Saved theme:', savedTheme);
      if (savedTheme !== null) {
        commit('setDarkTheme', JSON.parse(savedTheme));
        console.log('Theme initialized:', JSON.parse(savedTheme) ? 'dark' : 'light');
      } else {
        console.log('No saved theme found');
      }
    },
  },
});