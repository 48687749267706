<template>
    <div id="app" :class="{ 'dark-theme': isDarkTheme }">
      <nav>
        <router-link to="/">{{ $t('home') }}</router-link> |
        <router-link to="/create-transcription">{{ $t('createTranscription') }}</router-link> |
        <a v-if="isLoggedIn" @click="logout">{{ $t('logout') }}</a>
        <router-link v-else to="/login">{{ $t('login') }}</router-link> |
        <select v-model="$i18n.locale">
          <option value="en">English</option>
          <option value="ru">Русский</option>
          <option value="de">Deutsch</option>
          <option value="fr">Français</option>
        </select> |
        <button @click="toggleTheme">{{ isDarkTheme ? '☀️' : '🌙' }}</button>
      </nav>
      <router-view></router-view>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';

  export default {
    name: 'App',
    computed: {
      ...mapState(['isLoggedIn', 'isDarkTheme'])
    },
    created() {
      console.log('App created');
      this.checkLoginStatus();
      this.initTheme();
      this.applyTheme();
    },
    mounted() {
      console.log('App mounted, isDarkTheme:', this.isDarkTheme);
    },
    methods: {
      ...mapActions(['checkLoginStatus', 'logout', 'initTheme', 'toggleTheme']),
      handleLogout() {
        this.logout();
        this.$router.push('/login');
      },
      applyTheme() {
        document.documentElement.classList.toggle('dark-theme', this.isDarkTheme);
      },
    },
    watch: {
      isDarkTheme(newValue) {
        console.log('isDarkTheme changed:', newValue);
      },
      $route() {
        this.checkLoginStatus();
      }
    }
  }
  </script>
  
  <style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    margin-top: 60px;
  }

  nav {
    padding: 30px;
  }

  nav a {
    font-weight: bold;
  }

  nav a.router-link-exact-active {
    color: #42b983;
  }
  </style>