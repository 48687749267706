import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/components/HomeView.vue')
  },
  {
    path: '/create-transcription',
    name: 'CreateTranscription',
    component: () => import('@/components/CreateTranscription.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/transcription/:id',
    name: 'Transcription',
    component: () => import('@/components/Transcription.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/components/Login.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch('checkLoginStatus');
  if (to.matched.some(record => record.meta.requiresAuth) && !store.state.isLoggedIn) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;